import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { BaseComponent } from 'src/app/components/shared-nf-component/base-component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JobAnnouncement } from 'src/app/model/job-announcement';
import { QuotationDataService } from 'src/app/services/data/quotation-data.service';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import { JobAnnouncementDataService } from 'src/app/services/data/job-announcement-data.service';
import { Quotation } from 'src/app/model/quotation';
import { DmDialogComponent } from 'src/app/components/shared-component/dm-dialog/dm-dialog.component';
import { DataUtils } from 'src/app/utils/DataUtils';

/**
 * this comonent is used on Entreprise Side
 * Present the functionality of quotation 
 * validation by Entreprises profiles
 */
@Component({
  selector: 'app-quotation-jobannouncement',
  templateUrl: './quotation-jobannouncement.component.html',
  styleUrls: ['./quotation-jobannouncement.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('detailExpandQuotation', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class QuotationJobannouncementComponent extends BaseComponent implements OnInit {

    private readonly maxLengthTitle = 70;
    showJobannouncementDescription : boolean = true;

    jobAnnouncementDataSource = new MatTableDataSource<JobAnnouncement>();
    @ViewChild("jobAnnouncementPaginator") paginator: MatPaginator;
    jobAnnouncementdisplayedColumns: string[] = jobAnnouncementColumns.map((col) => col.key);
    columnsToDisplayWithExpand = [...this.jobAnnouncementdisplayedColumns, 'expand'];
    jobAnnouncementcolumnsSchema: any = jobAnnouncementColumns;
    expandedElement: JobAnnouncement | null;
    selectedAnnonceRef = '';
    selectedAnnonceId = '';
    jobAnnouncementStatus = '';
    jobAnnouncementList; 

    quotationDataSource = new MatTableDataSource<Quotation>();
    @ViewChild("quotationDataSourcePaginator") quotationPaginator: MatPaginator;
    quotationDisplayedColumns: string[] = quotationColumns.map((col) => col.key);
    quotationColumnsToDisplayWithExpand = [...this.quotationDisplayedColumns, 'expand'];
    quotationColumnsSchema: any = quotationColumns;
    quotationExpandedElement: Quotation | null;
    //quotationList;
    quotationList = new Map<any, Quotation[]>();


    constructor (
                    public dialog: MatDialog,
                    private jobAnnouncementService: JobAnnouncementDataService, 
                    private quotationService: QuotationDataService, 
                    private router : Router,
                    protected route: ActivatedRoute, 
                    protected dataUtils : DataUtils,
                    public data: ShareDataService ) { 
        super(data);
        this.jobAnnouncementService.getPiloteJobAnnouncementByStatus('PUBLISHED:ASSIGNED').subscribe((res: any) => {
          res.sort((first, second) => {
            if (first.startingdate == null || second.startingdate == null) {
              return -1;
            }
            return new Date(second.startingdate).getTime() - new Date(first.startingdate).getTime();
          });
          this.jobAnnouncementList=res;
          this.jobAnnouncementDataSource.data = res;
          this.jobAnnouncementDataSource.paginator = this.paginator;
        });    
    }

    gotopayment(){
      const url = this.router.serializeUrl(this.router.createUrlTree(['my-home/payment'], {
          queryParams: { 
            
            id: this.selectedAnnonceId,
            price:this.quotationExpandedElement.ttc
          } 
      }));
      window.open(url, '_blank');
      
    }

    openDialog (selectedAnnonceId: any, selectedQuotationId: any, 
        selectedAnnonceRef: any, selectedQuotationRef: any): void {
          let dialogRef = this.dialog.open(DmDialogComponent, {
          width: '250px', 
          data : {
              message : '- Etes-vous sur de vouloir valider le devis ' + selectedAnnonceRef + ' pour l\'annonce '  + selectedQuotationRef
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === 'validate' ) {
            this.accepteQuotationforCurrentJobAnnouncement(selectedAnnonceId, selectedQuotationId);
          }
          if (result === 'cancel') {
            console.log('The action was canceled');
          }
        });
    }



    /**
     * find a list of quotation according to the selected jobannouncement
     * 
     * @param id jobannoucement
     * @param ref jobannouncement
     * @param statux jobannouncement 
     */
    selectQuotationListByJobannouncementId (id : any, ref : any, status: any) {
      //this.quotationList=undefined;
      this.selectedAnnonceRef=ref;
      this.selectedAnnonceId = id;
      this.jobAnnouncementStatus=status;
      this.quotationService.getQuotationsEntrepriseList(id).subscribe((res: any) => {
        res.sort((first, second) => {
          if (first.quotationdate == null || second.quotationdate == null) {
            return -1;
          }
          return new Date(second.quotationdate).getTime() - new Date(first.quotationdate).getTime();
        });
          this.quotationList.set(id, res);
        this.quotationDataSource.data = res;
        this.quotationDataSource.paginator = this.quotationPaginator;
    });
  }

      /**
       * RG : 
            la validation entraine :
              - Le changement du status de l'annonce a MISSION ATTRIBUEE 
              - Le passage du devis du pilote concernée à ACCEPTE 
              - Le passage de tous les autres devis qui font objet de 
                    candidature sur la meme annonce à NON ACCEPTEE 
       * 
       * @param selectedAnnonceId 
       * @param quotationId 
       */
      accepteQuotationforCurrentJobAnnouncement(selectedAnnonceId: any, quotationId: any) {

          console.log (selectedAnnonceId + "," + quotationId);
            
          this.quotationService.acceptQuotationOnJobAnnouncement (quotationId, selectedAnnonceId)
          .subscribe((res: any) => {
            if (res.status === 'ACCEPTED') {
              this.quotationDataSource.data.forEach(quotation => {
                if (quotation.id === quotationId) {
                  quotation.status = 'ACCEPTED';
                }
                else {
                  quotation.status = 'REJECTED';
                }
              });
              this.expandedElement.status= 'ASSIGNED';
              this.jobAnnouncementStatus= 'ASSIGNED';
            }
          });
      }

      public truncateTitle(title: string) {
        return this.dataUtils.displayTitle(title, this.maxLengthTitle);
      }
    
      public afficher () {
        this.showJobannouncementDescription = !this.showJobannouncementDescription
      }
}

export const jobAnnouncementColumns = [
  {
    key: 'annonceref',
    type: 'text',
    label: 'Ref',
    required: true,
  },
  {
    key: 'title',
    type: 'text',
    label: 'Titre',
    required: true,
  },
  {
    key: 'startingdate',
    type: 'date',
    label: 'Date Début',
  },
  {
    key: 'status',
    type: 'badge',
    label: 'Statut',
  }
];

export const quotationColumns = [
  {
    key: 'quotationref',
    type: 'text',
    label: 'Ref',
    required: true,
  },
  {
    key: 'ttc',
    type: 'currency',
    label: 'Prix prestation',
  },
  {
    key: 'quotationdate',
    type: 'date',
    label: 'Date de début',
  },
  {
    key: 'availability',
    type: 'date',
    label: 'Date de validité',
  },
  {
    key: 'status',
    type: 'badge',
    label: 'Statut',
  },
  {
    key: 'valider',
    type: 'validation',
    label: '',
  },
];

