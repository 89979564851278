import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TokenInfo } from 'src/app/model/token-info';
import { UserInfo } from 'src/app/model/user-info';
import { CookieService } from '../cookie/cookie.service';
import jwt_decode from 'jwt-decode';
import { DecodedTokenInfo } from 'src/app/model/decoded-token-info';
import { Router } from '@angular/router';
import { TokenDataService } from '../data/token-data.service';
import { UserDataService } from '../data/user-data.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  tiresult :  TokenInfo; 

  constructor ( 
                private cookieService: CookieService, 
                private router : Router,
                private tokenDataService: TokenDataService 
              ) { }


  public isLoggedIn() {
    const access_token = this.getAccessToken ();
    if (access_token===null || access_token === undefined || access_token === ''  ) {
      return null;
    }

    if (this.isExpiredToken (access_token)) {
      return false;
    }
    else {
      return true;
    }
  }
   
  public getUserName () : string { 

    const access_token = this.getAccessToken ();
    
    if (access_token === null || access_token === undefined || access_token === '')
    {
      return null;
    }

    let decoded = jwt_decode(access_token);
    const dti = decoded as DecodedTokenInfo; 
    return dti.user_name;
  }

  public getRole () : string { 
    const access_token = this.getAccessToken ();
    if (access_token==null || access_token == "") {
      return null;
    }
    let decoded = jwt_decode(access_token);
    const dti = decoded as DecodedTokenInfo; 
    return dti.authorities[0];
  }

  public isExpiredToken (token : string) : boolean { 
    let decoded : DecodedTokenInfo;
    try {
      if (token !== null && token !== undefined && token !== '')
      {
          decoded = jwt_decode(token);
          const dti = decoded as DecodedTokenInfo; 
          return Date.now() >= (dti.exp * 1000);
      }
    } catch(error) {
      // Module.EjJx 
      // ==> https://github.com/auth0/jwt-decode/issues/65
      // ==> https://github.com/auth0/jwt-decode/issues/61
      console.log (error);
      throw Error('not valid token');
    }
    throw Error('not valid token');
  }

  public logout () {
    this.cookieService.deleteCookie ('refresh_token');
    this.cookieService.deleteCookie ('access_token');
    window.location.reload();
  }

  public getRefreshToken () : string {
    return this.cookieService.getCookie ("refresh_token")
  }

  public getAccessToken () : string {
    return this.cookieService.getCookie ("access_token");
  }
   
  public setRefreshToken (refresh_token) {
    this.cookieService.setCookie ("refresh_token", refresh_token, 15, '/' );
  }

  public setAccessToken (access_token) {
    this.cookieService.setCookie ("access_token", access_token, 7, '/' );
  }

  public startRefreshToken (refresh_token: string): Observable<any> {
    // api call to refresh token 
    const ti = new TokenInfo ("","",refresh_token,"","");
    return this.tokenDataService.refreshToken (ti);
  }
}
